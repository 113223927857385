import React from 'react'
import styles from './App.module.css'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'

function Sudoku() {
  return (
    <div>
      <p>Welcome to Sudoku</p>
    </div>
  )
}

function Home() {
  return (
    <div className={styles.App}>
      <ul>
        <li>
          <Link to="/sudoku">Sudoku</Link>
        </li>
      </ul>
    </div>
  )
}

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/sudoku">
          <Sudoku />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
